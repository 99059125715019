/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a4bd58e3-1efe-4d87-a755-37504e7a6282",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_q9KicoTrw",
    "aws_user_pools_web_client_id": "vhjf590pjkvncbpejc58a4a53",
    "oauth": {},
    "aws_content_delivery_bucket": "tfffa-20201128192733-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d26oicc6u5mdcj.cloudfront.net"
};


export default awsmobile;
