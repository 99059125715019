import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private username:any;
  private password:any;
  private newPassword:any;
  private confirmPassword:any;
  private incompleteUser:any;
  private loading:boolean = false;

  constructor(
  	private authService:AuthService, 
  	private router:Router) { }

  ngOnInit(): void {
  	this.isLogged();
  }



  isLogged(){
    this.authService.isLoggedIn()
    .then(isLogged => { 
      if(isLogged){
        this.router.navigateByUrl('');     
      }
    }).catch(error => {
        console.log(error);
    });
  }

  signIn(){
    this.loading=true;
  	this.authService.signIn(this.username,this.password)
    .then(loggedIn=>{
      if( loggedIn.status == "NEW_PASSWORD_REQUIRED" ){
        this.loading=false;
        this.incompleteUser = loggedIn.user
      }else if(loggedIn.status == "logged"){
        this.loading=false;
        this.router.navigateByUrl('');
  		}else{
        this.loading=false;
  			alert("Usuario o contraseña incorrecta");
  		}
  	}).catch(error => {
      this.loading=false;
  		console.log('error ',error);
  		alert("Usuario o contraseña incorrecta");
  	});
  }

  completeProfile(){
    if( !this.newPassword || !(this.newPassword == this.confirmPassword)){
      alert("Las contraseñas no coinciden.");
      return;
    }
    this.loading=true;
    this.authService.completeProfile(this.incompleteUser,this.newPassword)
    .then(success=>{
      if(success){
        this.loading=false;
        this.router.navigateByUrl('');
      }else{
        this.loading=false;
        alert("Error al actualizar información.");
      }
    })
    .catch(error=>{
      this.loading=false;
      console.log('error ',error);
      alert("Error al actualizar información.");
    });


  }

}
