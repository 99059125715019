import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private router: Router,private authService: AuthService ){}
	canActivate(
	    next: ActivatedRouteSnapshot,
	    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	    return this.authService.isLoggedIn().then(isLogged => { 
	  		if(isLogged){
	  			return true;
	  		}else{
	  			return this.router.parseUrl('/login');
	  		}
	  	 }).catch(() => {
	        return this.router.parseUrl('/login');
	     });
	}
  
}
